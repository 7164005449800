<template>
  <div class='main'>
    <router-view></router-view>
    <!-- 底部导航 -->
    <footer-nav currentId="taskCenter"></footer-nav>
  </div>
</template>

<script>
  import footerNav from "@components/footer-nav";
  export default {
    components: {
      footerNav
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    methods: {}
  };
</script>

<style lang='less' scoped>
.main {
  // overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 28vw;
  // margin-top: 60px;
}
</style>
