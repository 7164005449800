<!--  -->
<template>
<mt-tabbar v-model="selected">
  <mt-tab-item id="taskCenter" @click.native="handleClick">
    <img v-if="this.selected == 'taskCenter'" slot="icon" src="@assets/images/index/nav_taskCenter_on.png">
    <img v-else slot="icon" src="@assets/images/index/nav_taskCenter_off.png">
    签约大厅
  </mt-tab-item>
  <mt-tab-item id="myTasks" @click.native="handleClick">
    <img v-if="this.selected == 'myTasks'" slot="icon" src="@assets/images/index/nav_myTask_on.png">
    <img v-else slot="icon" src="@assets/images/index/nav_myTask_off.png">
    我的任务
  </mt-tab-item>
  <!-- <mt-tab-item id="individualBusiness" @click.native="handleClick">
    <img v-if="this.selected == 'individualBusiness'" slot="icon" src="@assets/images/index/nav_inb_on.png">
    <img v-else slot="icon" src="@assets/images/index/nav_inb_off.png">
    个体工商户
  </mt-tab-item> -->
  <mt-tab-item id="aboutUs" @click.native="handleClick">
    <img v-if="this.selected == 'aboutUs'" slot="icon" src="@assets/images/index/nav_aboutUs_on.png">
    <img v-else slot="icon" src="@assets/images/index/nav_aboutUs_off.png">
    个人中心
  </mt-tab-item>
</mt-tabbar>
</template>

<script>
export default {
  props: ['currentId'],
  data () {
    return {
      selected: this.$router.currentRoute.name || this.currentId || 'taskCenter'
    }
  },
  watch: {
    $route (to, from) {
      if (to.path == '/taskCenter') {
        this.selected = 'taskCenter'
      } else if (to.path == '/myTasks') {
        this.selected = 'myTasks'
      } else if (to.path == '/individual-business') {
        this.selected = 'individualBusiness'
      } else if (to.path == '/aboutUs') {
        this.selected = 'aboutUs'
      }
    }
  },
  methods: {
    handleClick () {
      const item = this.selected
      if (item == 'taskCenter') {
        this.$router.push('/taskCenter')
      } else if (item == 'myTasks') {
        this.$router.push('/myTasks')
      } else if (item == 'individualBusiness') {
        this.$router.push('/individual-business')
      } else if (item == 'aboutUs') {
        this.$router.push('/aboutUs')
      }
    }
  }
}
</script>
<style lang='less' scoped>
</style>
